import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemValorComponent } from 'src/app/components/produto/produto-item-valor/produto-item-valor.component';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    ProdutoItemValorComponent
  ],
  exports: [
    ProdutoItemValorComponent
  ],
  imports: [
    PipeModule,
    CommonModule
  ]
})
export class ProdutoItemValorModule { }
