<div class="destination-item" (click)="_click()">
      <div class="content">
          <h4>{{data.nome}}</h4>
          <div class="saiba-mais">
              <a routerLink="/servicos/passeios" title="Conheça {{data.nome}}">
                  <span>CONHEÇA</span>
                  <div class="bg-overlay"></div>
              </a>
          </div>
      </div>  
      <div class="bg"></div>  
      <img [src]="data.foto" width="100%" [alt]="data.nome" *ngIf="data.foto != null && data.foto != ''" />
      <img src="/images/sem-foto/default-2.jpg" [alt]="data.nome" width="100%" *ngIf="data.foto == null || data.foto == ''"  />
</div>    

