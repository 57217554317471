<button class="button" class="item" [ngClass]="{'active': isFavorito}" (click)="favoritar()" *ngIf="template == 'default'">
      <div class="item-header item-save">
            <div class="number">
                <span><i class="fa-solid fa-heart"></i></span>
            </div>
            <div class="text" *ngIf="!isFavorito">
                Salvar nos Favoritos
            </div>
            <div class="text" *ngIf="isFavorito">
                Salvo nos Favoritos
            </div>
      </div> 
</button>
<button class="button" [ngClass]="{'active': isFavorito}" (click)="favoritar()" *ngIf="template == 'two'">
      <img *ngIf="!isFavorito" width="20px" height="20px" defaultImage='/images/pixel/bg.png' lazyLoad="/images/template/icons/coracao-cinza.svg" alt="coração" />
      <img *ngIf="isFavorito" width="20px" height="20px" defaultImage='/images/pixel/bg.png' lazyLoad="/images/template/icons/coracao-branco.svg" alt="coração" />
      <span *ngIf="!isFavorito">salvar</span> 
      <span *ngIf="isFavorito">salvo</span> 
</button>
<loader *ngIf="loader"></loader>
