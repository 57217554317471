import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

declare var $:any; 

@Component({
  selector: 'to-up',  
  templateUrl: './to-up.component.html',
  styleUrls: ['./to-up.component.scss']
})
export class ToUpComponent implements OnInit {

  @ViewChild("up") up:any;
  
  constructor(
    private app: AppService
  ){} 
  
  /**
   * 
   * To up
   * 
   */
  _toUp(){

    this.app.toTop();

  }
  /**
   * 
   * Scroll
   * 
   */
  _scroll(){

    let up = this.up.nativeElement;

    $(window).scroll(() => {
        
      var fromTop     = $(window).scrollTop();
      var windowWidth = $(window).width();
      
      if(fromTop > 500){
        up.classList.add("animated","fadeIn","show");
      }else{
        up.classList.remove("show","animated","fadeIn");
      }

    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    this._scroll();
  }

}
