import { Directive, forwardRef, Renderer2, ElementRef, HostListener } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR, DefaultValueAccessor } from "@angular/forms";

const INTEGER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntegerDirective),
  multi: true,
};

@Directive({
  selector: '[integer]',
  providers: [
    INTEGER_CONTROL_VALUE_ACCESSOR, 
  ]
})
export class IntegerDirective extends DefaultValueAccessor{

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef) { 
    
    super(renderer2,el,false);
  
  }
  @HostListener('input', ['$event']) 
  onInput($event: any){
    return this.formatValue($event)
  }
  private formatValue($event: any){
    
    let valor    = $event.target.value
    let valorOut = ""  

    for (var i = 0; i < valor.length; i++) {
      
      if(!isNaN(parseInt(valor.charAt(i)))) {
        valorOut += valor.charAt(i);
      }
    }

    super.writeValue(valorOut);
    this.onChange(valorOut);
  
  }
  

}
