import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { ModuloNewsletterModule } from './modulo-newsletter/modulo-newsletter.module';
import { BannerSliderSlickComponent } from 'src/app/modulos/banner-slider-slick/banner-slider-slick.component';
import { FaleConoscoComponent } from 'src/app/modulos/fale-conosco/fale-conosco.component';
import { ProdutoDestaqueOneComponent } from 'src/app/modulos/produto-destaque-one/produto-destaque-one.component';
import { ProdutoDestaqueTwoComponent } from 'src/app/modulos/produto-destaque-two/produto-destaque-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { ModuloDestinosComponent } from 'src/app/modulos/modulo-destinos/modulo-destinos.component';
import { DestinoItemModule } from '../destinos/destino-item/destino-item.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { ModuloFaleConoscoModule } from './modulo-fale-conosco/modulo-fale-conosco.module';
import { ModuloDestinoModule } from './modulo-destino/modulo-destino.module';
import { ModuloBlogModule } from './modulo-blog/modulo-blog.module';
import { ModuloHorariosModule } from './modulo-horarios/modulo-horarios.module';

@NgModule({
  declarations: [
    Modulo,
    BannerSliderSlickComponent,
    ProdutoDestaqueOneComponent,
    ProdutoDestaqueTwoComponent,
    ModuloDestinosComponent,
    EnderecoComponent
  ],
  exports:[
    Modulo,
    BannerSliderSlickComponent,
    EnderecoComponent
  ],
  imports: [
    ModuloFaleConoscoModule,
    ModuloWhatsappModule,  
    DestinoItemModule,
    ProdutoPartialModule,
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    ModuloDestinoModule, 
    ModuloNewsletterModule,
    ModuloBlogModule,
    ModuloHorariosModule,
    CommonModule,
    PipeModule 
  ]
})
export class ModuloModule { }
