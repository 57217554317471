import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'dateFormatText'
})
export class DateFormatTextPipe implements PipeTransform {

  constructor(
    private DateService: DateService
  ){

  }

  transform(date:any, ...args: unknown[]):any {
    

    let data_inicial  = date;
    let month_inicial = this.DateService.getMonth(data_inicial);
    let year_inicial  = this.DateService.getYear(data_inicial);
    let day_inicial   = this.DateService.getDay(data_inicial);
    let month_inicial_str = this.DateService.getMonthString(data_inicial); 

    return day_inicial+" de "+this.DateService.getMonthString(data_inicial)+" de "+year_inicial;
    

  }

}
