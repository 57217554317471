import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max'
})
export class MaxPipe implements PipeTransform {

  transform(value: any,max: any): any {
    
    let limit = parseInt(max);

    if(value.length > limit){
      return value.substring(0,limit)+"...";
    }
    return value;

  }

}
