import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input("itens") itens;
  @Input("home") home:any       = true;
  @Input("btnSave") btnSave     = false; 
  @Input("produtoId") produtoId = null;
  @Input("isFavorito") isFavorito = false;
  public listItens = [{
    text: "Home",
    routerLink: "/"
  }];

  constructor(
    private router: Router
  ){ }

  /**
   * 
   * To route
   * 
   */
  toRoute(link){

    this.router.navigateByUrl(link); 

  }
  /**
   * 
   * Seta os itens do breadcrumbs
   * 
   */
  setItens(){
    
    if(typeof(this.itens) != "undefined"){
          
      if(this.home == false || this.home == "false"){
        this.listItens = [];
      }
      
      if(typeof(this.itens) == "string"){
        this.itens = JSON.parse(this.itens);
      }
    
      this.listItens = this.listItens.concat(this.itens);
    
    }  
  
  }
  /**
  * 
  * Inicializa as Funções
  * 
  * 
  */
  ngOnInit(){
   this.setItens();
  }

}
