<div class="module module-products">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                <div class="title">
                    <h2>{{data.titulo}}</h2>
                    <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
                </div>
            </div>    
      </div>
      <div class="module-content">
            <div class="container">
                  <div class="row">  
                        <div class="col-md-4" data-wow-delay="0.2.s" *ngFor="let p of data.produtos">
                              <produto-item [data]="p"></produto-item>
                        </div>      
                  </div>      
            </div>    
      </div>  
      <div class="center">   
            <a (click)="_route('/servicos')" routerLink="/servicos" title="Passeios" class="btn-custom btn-icon">
                  <div class="icon"> 
                        <i class="fa-solid fa-plus"></i> 
                  </div>
                  <span>Ver mais passeios</span>
            </a> 
        </div>  
</div>      