import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  constructor( 
    public dialog: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){ }
  /**
   * 
   * Delete item
   * 
   * 
   */
  send(){

    if(this.data.fnCallback != null){

      this.data.fnCallback(this.data);
      this.close();

    }else{
      this.data.self.fnCallbackConfirm(this.data);
      this.close();
    }
    
    
  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
  }

}
