import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorariosComponent } from 'src/app/modulos/horarios/horarios.component';
import { PartialModule } from '../../partial/partial.module';


@NgModule({
  declarations: [
    HorariosComponent
  ],
  exports: [
    HorariosComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ModuloHorariosModule{}
