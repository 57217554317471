import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class UsuarioService {

  public emitUsuarioLogin = new EventEmitter(); 
  public openModalLogin   = new EventEmitter();   

  constructor(){}

}
