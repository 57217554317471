<div class="dm-modal">
      <div class="dm-modal-title">
        <div class="close wow fadeIn" data-wow-delay="0.5s" (click)="dialog.close()">
          <img width="12px" height="12px" defaultImage="/images/pixel/bg.png" src="/images/template/icons/close.svg" alt="close" />
        </div>    
      </div> 
      <div class="dm-modal-content">
            <div class="item">
                  <span class="title">Para falar sobre Passeios</span>
                  <button type="button" class="btn-one" (click)="open(1)">
                        Clique aqui
                  </button>      
            </div>
            <div class="item">
                  <span class="title">Para falar sobre Transfer</span>
                  <button type="button" class="btn-three" (click)="open(2)">
                        Clique aqui
                  </button>  
            </div>      
      </div>     
</div>    
