<section class="page-home">  
  <modulo posicao="banner-slider"></modulo>
  <modulo posicao="produto-destaque-1"></modulo>
  <modulo posicao="banner-destaque"></modulo>  
  <modulo posicao="destinos"></modulo>
  <modulo posicao="newsletter"></modulo>
  <modulo posicao="whatsapp"></modulo> 
</section>   

 



