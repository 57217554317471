import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  @ViewChild("slider") slider:any;
  @Input("data") data:any           = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template       = ""; 

  constructor(
    private router: Router
  ){}  

  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: true,
      autoplay: true,
      centerPadding: '20px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * To blog
   * 
   */
  toBlog(){

    this.router.navigateByUrl("blog");  

  }
  /**
   * 
   * Init
   * 
   **/
  ngOnInit():void{}
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit():void{
    this.initSlider();  
  }

}
