<div class="dm-input-date-range {{classCustom}}" *ngIf="showCalendar">
  <label *ngIf="label != null">{{label}}</label>
  <div class="content-input">
    <input type="text"  name="date" #dateInput name="date" /> 
    <div class="icon" (click)="_open()">
      <span class="material-icons">
        calendar_today
      </span>
    </div>
  </div>  
</div>      
    