import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinoItemComponent } from 'src/app/components/destinos/destino-item/destino-item.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DestinoRoutingModule } from '../destinos-routing.module';

@NgModule({
  declarations: [
    DestinoItemComponent
  ],
  exports: [
    DestinoItemComponent
  ],
  imports: [ 
    CommonModule
  ]
})
export class DestinoItemModule { }
