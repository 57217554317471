import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuloLogo } from 'src/app/modulos/logo/logo.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [
    ModuloLogo
  ],
  exports: [
    ModuloLogo  
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule
  ]
})
export class ModuloLogoModule { }
