<div class="modulo">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 class="section-title-h2 wow slideInUp" data-wow-delay="0.1s" [innerHtml]="data.titulo"></h2>
                  <h3 *ngIf="data.mostrar_subtitulo" class="section-title-h3 wow fadeIn" data-wow-delay="0.2s" [innerHtml]="data.subtitulo"></h3>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container relative">
                  <div class="produtos slider-items" #slider>  
                        <div class="slider-item wow slideInUp" data-wow-delay="0.2.s" *ngFor="let p of data.produtos">
                              <produto-item [maxText]="60" [data]="p"></produto-item>
                        </div>      
                  </div> 
                  <div class="swiper-button-prev sw-btn" (click)="prev()">
                        <img width="15px" height="15px" defaultImage="/images/pixel.png" lazyLoad="/images/template/icons/prev.svg" alt="Arrow" />
                  </div>
                  <div class="swiper-button-next sw-btn" (click)="next()">
                        <img width="15px" height="15px" defaultImage="/images/pixel.png" lazyLoad="/images/template/icons/next.svg" alt="Arrow" />
                  </div>     
            </div>    
      </div>  
</div>      