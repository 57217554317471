import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'partial-compra-segura',
  templateUrl: './partial-compra-segura.component.html',
  styleUrls: ['./partial-compra-segura.component.scss']
})
export class PartialCompraSeguraComponent implements OnInit {

  @Input("classCustom") classCustom = null;

  constructor() { }

  /**
   * 
   * Init
   * 
   * 
   */
  ngOnInit():void{} 
}
