import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    /**
     * 
     * Callback
     * 
     * @param req 
     * @param next 
     * 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        req = req.clone({
            setHeaders: {
            'Content-Type' : 'application/json; charset=utf-8',
            'Accept'       : 'application/json',
            'X-CSRF-TOKEN': `${AppService.getToken()}`,
        },
    });

    return next.handle(req);
  }
 
} 