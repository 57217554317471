import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-floating',
  templateUrl: './menu-floating.component.html',
  styleUrls: ['./menu-floating.component.scss']
})
export class MenuFloatingComponent implements OnInit {

  constructor(
    private router:Router
  ){}

  /**
   * 
   * Route
   * 
   */
  _route(link:any){

    this.router.navigateByUrl(link); 

  }
  /**
   * 
   * Init
   * 
   * 
   **/
  ngOnInit():void{}  

}
