<div class="module module-destinations">
      <div class="module-title wow fadeIn" data-wow-delay="0.8s" *ngIf="data.mostrar_titulo">
            <div class="container">
                <div class="title">
                    <h2>{{data.titulo}}</h2>
                    <h3>{{data.subtitulo}}</h3>
                </div>  
            </div>
      </div>
      <div class="module-content">
            <div class="container">
                <div class="destinations slider-items slick-padding-mobile" #slider>
                    <div class="slide-item" *ngFor="let d of data.cidades">
                        <destino-item [data]="d"></destino-item>
                    </div>  
                </div>
                <div class="content-navigate">
                    <div class="slide-navigate-item prev" (click)="prev()">
                        <i class="fa-solid fa-angle-left"></i>
                    </div>
                    <div class="slide-navigate-item next" (click)="next()">
                        <i class="fa-solid fa-angle-right"></i>
                    </div> 
                </div>
            </div>
        </div>    
</div>
      
