import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'btn-lista-desejo',
  templateUrl: './btn-lista-desejo.component.html',
  styleUrls: ['./btn-lista-desejo.component.scss']
})
export class BtnListaDesejoComponent implements OnInit {

  constructor(
    private router: Router
  ){ } 

  /**
   * 
   * On Click
   * 
   */
  _onClick(){

    this.router.navigateByUrl("/minha-conta/favoritos"); 

  }
  /**
   * 
   * Inicialza as Funções
   * 
   */
  ngOnInit(): void {}

}
