<div [ngSwitch]="template">
    <div class="btn-login" *ngSwitchCase="null">
        <div class="item" (click)="toRoute('/minha-conta')" *ngIf="usuario == null">
            <div class="icon">
                <i class="fa-solid fa-user"></i>
            </div>
            <!--<div class="text">
                <span>Minha <br>Conta</span>
            </div>-->
        </div>  
        <div class="item" (click)="toRoute('/minha-conta/perfil')" *ngIf="usuario !== null">
            <div class="icon">
                <i class="fa-solid fa-user"></i>
            </div>
            <!--<div class="text">   
                <span>Minha <br>Conta</span>
            </div>-->
        </div>       
    </div>
    <div class="btn-login two" *ngSwitchCase="'two'"> 
        <button type="button" (click)="_open()">
            <span>Já tenho uma conta</span>
        </button> 
    </div>
    <div class="btn-login three" *ngSwitchCase="'three'">   
        <button type="button" (click)="_open(1)">
            <span>Clique Aqui</span>
        </button> 
    </div>     
</div>        
