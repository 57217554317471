import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPassageiroDesc'
})
export class TipoPassageiroDescPipe implements PipeTransform {

  transform(value: any, args?: any): any {
      
    let desc = "";

    switch(value){
      case "adt":
        desc = "Adulto";
      break;
      case "snr":
        desc = "Melhor Idade";
      break;
      case "chd":
        desc = "Criança pagante";
      break;
      case "inf":
        desc = "Criança cortesia";
      break;
    }

    return desc;
    
  }

}
