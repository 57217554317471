import { Directive, HostListener, Input, Host, ElementRef, EventEmitter, Output, Renderer2, forwardRef } from '@angular/core';
import { NgControl } from "@angular/forms";
import { 
  NG_VALUE_ACCESSOR, ControlValueAccessor, DefaultValueAccessor
} from '@angular/forms';

const MASK_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneMaskDirective),
  multi: true,
};

@Directive({
  selector: '[phoneMask]',
  providers: [
    MASK_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class PhoneMaskDirective extends DefaultValueAccessor{

  onTouched: any;
  onChange: any;
  public code = null;

 
  constructor(
    private renderer2: Renderer2,
    private el: ElementRef
  ){ 
    
    super(renderer2,el,false);
  
  }
  @HostListener('keydown',['$event']) 
  onKeyDown($event: any){

    this.code = $event.keyCode;


  }
  @HostListener('input',['$event']) 
  onInput($event: any){

    return this.formatValue($event) 
    
  }
  private formatValue($event: any){

    var val = $event.target.value.replace(/\D/g, '');

    if (!val) {
      return '';
    }
    var formatNumber = '';
    val = val.replace(/\D/g,'');
    if(val.length != 0 && val.length < 3){
      formatNumber = val;
    }
    else if(val.length != 0 && val.length < 7){
      formatNumber = '(' + val.substring(0,2) + ') ' + val.substring(2,6);
    }
    else if(val.length != 0 && val.length < 11){
      formatNumber = '(' + val.substring(0,2) + ') ' + val.substring(2,6) + '-' + val.substring(6,10);
    }else if(val.length >= 11){
      formatNumber = '(' + val.substring(0,2) + ') ' + val.substring(2,7) + '-' + val.substring(7,11);
    }
      
    super.writeValue(formatNumber);
    this.onChange(formatNumber);
  }

  @HostListener('blur', ['$event']) 
  onBlur($event: any) {
    
    if ($event.target.value.length) {
      return;
    }
    this.onChange('');
    $event.target.value = '';
 
  }


}
