import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-info-error',
  templateUrl: './modal-info-error.component.html',
  styleUrls: ['./modal-info-error.component.scss']
})
export class ModalInfoErrorComponent implements OnInit {

  public title      = "Houve um erro inesperado na requisição. Tente novamente e caso o erro persista entre em contato com a nossa equipe.";
  public detail:any = null;
  public showMore   = false;

  constructor(
    public dialog: MatDialogRef<ModalInfoErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){  

    this.detail = typeof(this.data.detail) != "undefined" ? this.data.detail : null;

    if(this.data.title != null && this.data.title != ""){
      this.title = this.data.title;
    }

  }
  /**
   * 
   * Show more
   * 
   */
  _showMore(){

    this.showMore = this.showMore ? false : true;

  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}
