import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteBase } from '../routes/base';

@Injectable({
  providedIn: 'any',
})
export class ApiService {

  constructor(
    private http: HttpClient  
  ){ }

  /***
   * 
   * Conteúdo
   * 
   */
   blog(){

    return {
      post: (apelido):any => {  

        let url  = RouteBase.BLOG.post+"/"+apelido;

        return this.http.get(url);   

      }
    }

  }
  /**
   * 
   * Transfer
   * 
   */
  transfer(){
    return{
      reserva: (dataItem):any => {  
 
        let url    = RouteBase.TRANSFER.reserva.url;
        let params = dataItem;

        return this.http.post(url,params);      

      }
    }
  }
  /**
   * 
   * Assistencia
   * 
   * 
   */
  assistencia(){
    return{
      show: (apelido):any => {  
 
        let url = RouteBase.ASSISTENCIA.url+"/show?apelido="+apelido;

        return this.http.get(url);      

      }
    }
  }
  /***
   * 
   * Conteúdo
   * 
   */
  destino(){

    return {
      show: (apelido):any => {  

        let url = RouteBase.DESTINO.url+"/show/"+apelido;

        return this.http.get(url);   

      }
    }

  }
  /***
   * 
   * Conteúdo
   * 
   */
  conteudo(){

    return {
      page: (apelido,params={}):any => {  

        let url    = RouteBase.CONTEUDO.page+"/"+apelido;

        return this.http.get(url,{params});   

      }
    }

  }
  /**
   * 
   * Contato
   * 
   */
  newsletter(){

    return{
      natal: (dataItem):any=> {

        let url = RouteBase.MODULO.newsletter_natal_luz; 
        return this.http.post(url,dataItem)
      
      }
    }

  }
  /**
   * 
   * Contato
   * 
   */
  contato(){

    return{
      fale_conosco: (dataItem):any=> {

        let url = RouteBase.CONTATO.fale_conosco; 
        return this.http.post(url,dataItem);
      
      },
      trabalhe_conosco: (dataItem):any=> {

        let url = RouteBase.CONTATO.trabalhe_conosco; 
        return this.http.post(url,dataItem);
      
      },
      send: (dataItem):any=> {

        let url = RouteBase.CONTATO.send;   
        return this.http.post(url,dataItem);
      
      }
    }

  }
  /**
   * 
   * Serviço
   * 
   */
  produto(){

    return {
      destino_produtos: (apelido):any=> {

        let url = RouteBase.PRODUTO.destino_produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      regiao_produtos: (apelido):any=> {

        let url = RouteBase.PRODUTO.regiao_produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      produtos: (apelido):any=> {

        let url = RouteBase.PRODUTO.produtos+"?apelido="+apelido; 
        return this.http.get(url,{})
      
      },
      favoritar: (id):any => {

        let url    = RouteBase.PRODUTO.favoritar;  
        let params = {
          id: id
        }
        return this.http.post(url,params);

      },
      search: (params):any=> {

        let url = RouteBase.PRODUTO.search; 
        return this.http.get(url,{params}) 
      
      },
      cidade: (apelido):any=> {

        let url = RouteBase.PRODUTO.cidade+"/"+apelido; 
        return this.http.get(url,{})
      
      },
      send_contato: (dataItem):any=> {

        let url = RouteBase.PRODUTO.send_contato; 
        return this.http.post(url,dataItem)
      
      },
      categorias: (apelido):any=> {

        let url = RouteBase.PRODUTO.categorias; 
        return this.http.get(url,{})
      
      },
      produto: (apelido):any=> {

        let url = RouteBase.PRODUTO.produto+"?apelido="+apelido; 
        return this.http.get(url,{})

      },
      solicitacao_reserva: (dataItem):any=> {

        let url = RouteBase.PRODUTO.solicitacao_reserva; 

        return this.http.post(url,dataItem)

      },
      avaliacao: (dataItem):any=> {

        let url = RouteBase.PRODUTO.avaliacao; 

        return this.http.post(url,dataItem) 

      },
      avaliacoes: (id,skip):any=> {

        let url = RouteBase.PRODUTO.avaliacoes+"/"+id+"/"+skip; 

        return this.http.get(url,{})

      },
      aeroporto:  {
        combo: ():any => { 
          
          let url = RouteBase.PRODUTO.aeroporto.combo; 
          return this.http.get(url,{})
        
        }
      },
      cia_aerea:  {
        combo: ():any => { 
          
          let url = RouteBase.PRODUTO.cia_aerea.combo; 
          return this.http.get(url,{})
        
        }
      }
    }

  }
   /**
   * 
   * Cart
   * 
   * 
   */
  cart(){

    return{
      get: ():any => {

        let url = RouteBase.CART.base;
        
        return this.http.get(url,{})

      },
      insert: (params):any => {
        
        let url = RouteBase.CART.base;

        return this.http.post(url,params)

      },
      edit: (params):any => {
        
        let url = RouteBase.CART.base+"/"+params.id;

        return this.http.put(url,params);

      },
      delete: (id):any => {
        
        let url = RouteBase.CART.base+"/"+id;

        return this.http.delete(url)

      },
      lista_paxs: ():any => {

        let url = RouteBase.CART.lista_paxs;
        
        return this.http.get(url,{})

      },
      abandonar_compra: ():any => {

        let url = RouteBase.CART.abandonar_compra;
        
        return this.http.post(url,{})
      },
      esvaziar: ():any => {

        let url = RouteBase.CART.esvaziar;
        
        return this.http.post(url,{})

      },
      dados_comprador: (params):any => {

        let url = RouteBase.CART.dados_comprador;
        
        return this.http.post(url,params)

      },
      get_cart_pagamento: ():any => {

        let url = RouteBase.CART.get_cart_pagamento;
        
        return this.http.get(url,{})

      },
      get_cart_file_gerado: ():any => {

        let url = RouteBase.CART.get_cart_file_gerado;
        
        return this.http.get(url,{})

      },
      update_passageiro_titular: (id):any => {

        let url    = RouteBase.CART.update_passageiro_titular;

        let params = {
          id: id
        }
        
        return this.http.put(url,params) 

      }

    }

  }
  /**
   * 
   * Managetour
   * 
   */
  manage(){

    return{
      gerarVoucher: (file):any => {

        let url = RouteBase.MANAGE.gerar_voucher+"/"+file;
          
        return this.http.get(url)

      },
      validarDisponibilidade: (
        idServico,
        dataUtilizacao,
        idTipoServico,
        qtdAdt,
        qtdChd, 
        qtdInf,
        qtdSnr 
      ):any => {

        let url    = RouteBase.MANAGE.validar_disponibilidade;
        let params = {
          id_servico: idServico,
          data_utilizacao: dataUtilizacao,
          id_tipo_servico: idTipoServico,
          qtd_adt: qtdAdt,
          qtd_chd: qtdChd,
          qtd_inf: qtdInf,
          qtd_snr: qtdSnr
        }

        return this.http.get(url,{params})

      },
      listaTiposServicosById: (id,idMacroRegiao):any => {

        let url = RouteBase.MANAGE.lista_tipo_servicos_by_id+"/"+id+"/"+idMacroRegiao; 
        return this.http.get(url)

      },
      hoteis: (id=null): any => {

        let url  = RouteBase.MANAGE.hoteis;
        if(id != null){
          url += "/"+id;
        }

        return this.http.get(url)

      },
      origem: (id): any => {

        let url  = RouteBase.MANAGE.origem+"/"+id;
    
        return this.http.get(url)

      },
      destino: (id): any => {

        let url  = RouteBase.MANAGE.destino+"/"+id;
    
        return this.http.get(url)

      },
      regioes: (): any => {

        let url  = RouteBase.MANAGE.regioes;
        return this.http.get(url)

      },
      consultarReserva: (data): any => {

        let url  = RouteBase.MANAGE.consultar_reserva;
        
        let params = {
          file: data.file,
          ultimonome: data.ultimonome
        } 
        
        return this.http.get(url,{params})

      },
      autenticar: (usuario,senha,tipo):any => {

        let url = RouteBase.MANAGE.autenticar;    
        
        let params = {
          usuario: usuario,
          senha: senha,
          tipo: tipo
        }

        return this.http.post(url,params)

      },
      logout: ():any => {

        let url = RouteBase.MANAGE.logout;
        
        return this.http.post(url,{})

      },
      disponibilidade: (
        idServico,
        idTipoServico,
        idMacroRegiao,
        idTipoTransporte,
        mes,
        ano,
        qtdPaxs=0
      ):any => {

        let url   = RouteBase.MANAGE.disponibilidade;
        let params:any = {
          macro_regiao_id: idMacroRegiao,
          servico_id: idServico,
          tipo_servico_id: idTipoServico,
          tipo_transporte_id: idTipoTransporte,
          mes: mes,
          ano: ano,
          qtd_paxs: qtdPaxs
        };
        return this.http.get(url,{params});

      },
      updatePassageiros: (dataItem):any => {

        let url    = RouteBase.MANAGE.update_passageiros;
        let params = dataItem; 

        return this.http.post(url,params); 

      }
    }

  }
  /**
   * 
   * Pagamento
   * 
   */
  pagamento(){

    return {
      consultar: (params:any):any => {

        let url = RouteBase.PAGAMENTO.consultar; 

        return this.http.post(url,params);

      },
      consultarLink: (params:any):any => {

        let url = RouteBase.PAGAMENTO.consultar_link; 

        return this.http.post(url,params);

      },
      updatePassageiros: (params):any => {

        let url = RouteBase.PAGAMENTO.update_passageiros; 

        return this.http.put(url,params);

      },
      updateDados: (params):any => {

        let url = RouteBase.PAGAMENTO.update_dados; 

        return this.http.put(url,params);

      },
      finalizar: (params):any => {

        let url = RouteBase.PAGAMENTO.finalizar; 

        return this.http.post(url,params)

      },
      finalizarReserva: (params):any => {

        let url = RouteBase.PAGAMENTO.finalizar_reserva;

        return this.http.post(url,params)

      },
      enviarConfirmacaoEmail: (email,id,pagafacilId):any => {

        let url = RouteBase.PAGAMENTO.enviar_confirmacao_email;
        let params = {
          id: id,
          pagafacil_id: pagafacilId,
          email: email
        }

        return this.http.post(url,params)

      },
      dadosLinkPagamento: (file):any => {

        let url = RouteBase.PAGAMENTO.dados_link_pagamento+"/"+file;
  
        return this.http.get(url)
  
      },
      finalizarPagafacil: (params):any => {

        let url = RouteBase.PAGAMENTO.finalizar_pagafacil;

        return this.http.post(url,params)

      }
    }
  
  }
  /**
   * 
   * Modulos
   * 
   */
  modulos(){

    return {
      getModulos: ():any => {

        let url = RouteBase.MODULO.modulos;
 
        return this.http.get(url);

      },
      newsletter: (params):any => {
        
        let url = RouteBase.MODULO.newsletter
        return this.http.post(url,params);
      
      }
    }

  }
  /**
   * 
   * Loja
   * 
   */
  loja(){

    return {
      pagamento: () => {
        return{
          pagafacil: () => {
            return {
              show: ():any => {
                
                let url = RouteBase.LOJA.pagamento.pagafacil.url;
                
                return this.http.get(url,{});
              
              }
            }
          }
        }
      },
      carrinho: () => {
        return{
          get: (tipo=null):any => {
    
            let url = RouteBase.LOJA.carrinho.base+"?tipo="+tipo;
            
            return this.http.get(url,{})
    
          },
          store: (params):any => {
            
            let url = RouteBase.LOJA.carrinho.base;
    
            return this.http.post(url,params)
    
          },
          edit: (params):any => {
            
            let url = RouteBase.LOJA.carrinho.base+"/"+params.id;
    
            return this.http.put(url,params);
    
          },
          delete: (id):any => {
            
            let url = RouteBase.LOJA.carrinho.base+"/"+id;
    
            return this.http.delete(url)
    
          },
          listaPaxs: ():any => {
    
            let url = RouteBase.LOJA.carrinho.lista_paxs;
            
            return this.http.get(url,{})
    
          },
          abandonarCompra: ():any => {
    
            let url = RouteBase.LOJA.carrinho.abandonar_compra;
            
            return this.http.post(url,{})
          },
          esvaziar: ():any => {
    
            let url = RouteBase.LOJA.carrinho.esvaziar;
            
            return this.http.post(url,{})
    
          },
          dadosComprador: (params):any => {
    
            let url = RouteBase.LOJA.carrinho.dados_comprador;
            
            return this.http.post(url,params)
    
          },
          getCartPagamento: ():any => {
    
            let url = RouteBase.LOJA.carrinho.get_cart_pagamento;
            
            return this.http.get(url,{})
    
          },
          getCartFileGerado: ():any => {
    
            let url = RouteBase.LOJA.carrinho.get_cart_file_gerado;
            
            return this.http.get(url,{})
    
          },
          updatePassageiroTitular: (id):any => {
    
            let url    = RouteBase.LOJA.carrinho.update_passageiro_titular;
    
            let params = {
              id: id
            }
            
            return this.http.put(url,params) 
    
          }
    
        }
      },
      usuario: () => {
        return {
          store: (params):any => {

            let url = RouteBase.LOJA.usuario;
            return this.http.post(url,params);

          },
          update: (params):any => {

            let url = RouteBase.LOJA.usuario;
            return this.http.put(url,params);

          }
        }
      },
      cupom: ():any => {
        return{
          validar: (cupom):any => {

            let url = RouteBase.LOJA.cupom.validar;
            let params = {
              cupom: cupom
            }
            return this.http.get(url,{params});

          }
        }
      },
      logout: ():any => {
        let url = RouteBase.LOJA.logout;
        return this.http.post(url,{});
      },
      login: (params):any => { 
        let url = RouteBase.LOJA.login; 
        return this.http.post(url,params);
      },
      login_social: (params):any => {  
        let url = RouteBase.LOJA.login_social; 
        return this.http.post(url,params);
      },
      email_senha: (email):any => {  
        let url    = RouteBase.LOJA.email_senha; 
        let params = {
          email: email
        }
        return this.http.post(url,params);
      },
      update_senha: (params):any => {  
    
        let url    = RouteBase.LOJA.update_senha; 
        return this.http.put(url,params); 
    
      }
    }

  }
  /**
   * 
   * Formata as mensagens de erro 
   * 
   * @param err 
   * @param paxs 
   * 
   */
  formarErrorsValidation(err,paxs=null){
    
    let messages = ""
    
    if(typeof(err) == "string"){
      messages = err
    }else{
      if(err.length > 1){
           
        for(let key in err) {
          if(paxs){
            let i = parseInt(key)+1 
            messages += "<h6>Passageiro: "+i+"<h6>"
          }
          for(let k in err[key]){
            messages += err[key][k]+"<br>"
          }
                   
        }
      }else{
        for(let key in err[0]) {
          messages += err[0][key]+"<br>"
        }
     }  
    
    }
    
    return messages
    
  }
  /**
   * 
   * Formata o erro da API
   * 
   */
  formatError(response){

    let error = {
      status: null,
      statusText: null,
      message: null
    }

    if(response.statusText){
      error.statusText = response.statusText;
    }
    if(response.status){
      error.status = response.status;
    }
    if(error.status == 405){
      error.message = "Método não permitido.";
    }
    if(response.error && error.message == null){
      
      let body = response.error;

      if(error.status == 422){

        error.message = this.formarErrorsValidation([body.errors]);

      }else if(body.message){
        error.message = body.message;
      }

    }else{
      error.message = response.data;
    }

    return error;


  }

}
