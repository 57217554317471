import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideCarouselComponent } from 'src/app/components/slide/slide-carousel/slide-carousel.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    SlideCarouselComponent
  ],
  exports: [
    SlideCarouselComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class SlideCarouselModule { }
