import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {

  @Input("data") data         = null;
  @Input("showLink") showLink = false;

  constructor(
    private router:Router
  ){} 

  /**
   * 
   * To route
   * 
   */
  toRoute(){

    let url = "/blog/"+this.data.apelido_categoria+"/"+this.data.apelido; 

    this.router.navigateByUrl(url); 
    return false;

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {}

}
