import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-destino',
  templateUrl: './destino.component.html',
  styleUrls: ['./destino.component.scss']
})
export class DestinoComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template       = "";

  constructor(
    private router: Router
  ) { }  

  /**
  * 
  * To route
  * 
  */ 
  toRoute(){

    this.router.navigateByUrl("/destino"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
