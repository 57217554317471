import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[typeWrite]'
})
export class TypeWriteDirective implements OnInit {

  @Input("typeWrite") texto = "";

  constructor(
    private el: ElementRef 
  ){ 
 
  }
  /**
   * 
   * Escrever
   * 
   */
  escrever(){

    let self = this;
    const textoArray = this.texto.split('');
    this.el.nativeElement.innerHtml = ' ';

    textoArray.forEach(function(letra, i){   
      
      setTimeout(function(){  
        self.el.nativeElement.innerHTML += letra;  
      }, 75 * i)

    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.escrever();  
  }

}
