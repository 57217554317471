import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateService } from 'src/app/services/date.service';

declare var $:any;

@Component({
  selector: 'input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrls: ['./input-date-range.component.scss']
})
export class InputDateRangeComponent implements OnInit,AfterViewInit {

  @Input("label") label    = null;
  @ViewChild("dateInput") dateInput:ElementRef;
  @Output("change") change = new EventEmitter();  
  @Input("clear") clear    = new EventEmitter();
  @Input("classCustom") classCustom = "";
  public dataItem:any      = {};
  public show              = false;
  public showCalendar      = false;

  constructor(
    public date: DateService
  ){}
    
  /**
   * 
   * open
   * 
   */
  _open(){  

    try{
      if(!this.show){
        $(this.dateInput.nativeElement).data('daterangepicker').show();
        this.show = true;
      }else{
        $(this.dateInput.nativeElement).data('daterangepicker').hide();
        this.show = false;
      }
    }catch(e){
      alert(e.message);
    }

  }
  /**
   * 
   * Init calendar
   * 
   */
  initCalendar(){

    let today = this.date.today();
    let self  = this;
    this.dataItem.date_in  = today;
    this.dataItem.date_out = today;

    this.change.emit({
      date_in: this.dataItem.date_in,
      date_out: this.dataItem.date_out
    }); 

    if(typeof(this.dateInput.nativeElement) != "undefined"){
      $(this.dateInput.nativeElement).daterangepicker({
        opens: 'left',
        autoUpdateInput: true,
        locale: {
          format: 'DD/MM/YYYY', 
          cancelLabel: 'Cancelar',
          toLabel: "a",  
          separator: " a ",
          applyLabel: 'Aplicar',
          "daysOfWeek": [
            "Dom",
            "Seg",
            "Ter",
            "Qua",
            "Qui",
            "Sex",
            "Sab"
          ],
          "monthNames": [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ]
        } 
      },function(start, end, label) {
        
        let dateIn  = start.format('DD-MM-YYYY');
        let dateOut = end.format('DD-MM-YYYY');


        self.dataItem.date_in  = dateIn; 
        self.dataItem.date_out = dateOut;
      
        
        self.change.emit({
          date_in: dateIn,
          date_out: dateOut
        });

      });
      $(this.dateInput.nativeElement).on('cancel.daterangepicker', function(ev, picker) {
        //self.show = false;
      });
      $(this.dateInput.nativeElement).on('show.daterangepicker', function(ev, picker) {
        //self.show = true;
      });
      $(this.dateInput.nativeElement).on('hide.daterangepicker', function(ev, picker) {
        //self.show = false;
      });
    }

  }
  /***
   * 
   * Init dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      date: null,
      date_in: null,
      date_out: null  
    }

  }
  /**
   * 
   * On data
   * 
   */
  onData(){


    this.clear.subscribe(data => {

      this.showCalendar = false;

      setTimeout(() => {
        this.showCalendar = true;
        setTimeout(() => {
          this.initCalendar(); 
        },100);    
      },100);
  
    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.showCalendar = true;
    this.onData();
    this.initDataItem();
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initCalendar();
    },500);
  }

}
