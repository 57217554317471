import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'getTarifaMinimaLista'
})
export class GetTarifaMinimaListaPipe implements PipeTransform {

  constructor(
    private app: AppService
  ){

  }

  transform(lista: any, args?: any): any {
    
    if(typeof(lista) || "array"){

      if(lista.length == 1){
        return this.app.formatarValor(lista[0].tarifaMinima);
      }else{

        let valor = null;

        for (let index = 0; index < lista.length; index++) {
          
          if(valor == null){
            valor = this.app.formatarValor(lista[index].tarifaMinima);
          }else{

            if(valor > this.app.formatarValor(lista[index].tarifaMinima)){
              valor = this.app.formatarValor(lista[index].tarifaMinima);
            }

          }
          
        }

        return valor;

      }  
    
    }

    return lista;
    
  
  }

}
