import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  @Input("data") data; 
  @Input("template") template       = null; 
  @Input("classCustom") classCustom = "";
  @Input("endereco") endereco;   

  constructor(){ }

  /**
   * 
   * Open
   * 
   */
  open(){

    window.open("https://g.page/SaoPauloEcoturismo?share","_blank");

  }
  /**
   * 
   * Inicializa as Funções
   * 
   **/
  ngOnInit(){}

}
