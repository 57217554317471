import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'formatValorManager'
})
export class FormatValorManagerPipe implements PipeTransform {

  constructor(
    private app: AppService
  ){

  }
  /**
   * 
   * 
   * @param value 
   * @param args 
   * 
   */
  transform(valor: any,args?: any): any {
    
    return this.app.formatarValor(valor);
  
  }

}
