<div class="slide-carousel">
      <div class="slider-items" #slider>  
          <div class="slider-item" *ngFor="let d of data">
              <img [src]="d.imagem" width="100%" />
          </div>
      </div>
      <div class="slide-navigate-item prev" (click)="prev()"> 
          <i class="fa-solid fa-angle-left"></i>
          <div class="bg"></div>
      </div>
      <div class="slide-navigate-item next" (click)="next()">
          <i class="fa-solid fa-angle-right"></i>
          <div class="bg"></div>
      </div>
</div>     