<div class="produto-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
    <div class="capa">
       <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
         <img defaultImage="/images/produto/sem-foto/default.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.foto_alt" class="img-fluid" /> 
       </a>  
       <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
         <img itemprop="image" src="/images/produto/sem-foto/default.jpg" alt="Sem Foto" class="img-fluid" />
       </a> 
     </div>
     <div class="content"> 
       <div class="box">
         <div class="titulo"> 
           <h4 itemprop="name">  
             {{produto.nome | max:maxText}}      
           </h4> 
         </div>
         <article *ngIf="produto.descricao_listagem != null" [innerHtml]="produto.descricao_listagem | safeHtml"></article>
       </div>
       <div class="box box-valor">   
         <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
       </div> 
       <div class="box footer">
          <a href='javascript::void();' (click)="_click();" class="btn-one full" title="{{produto.nome}}">
            COMPRAR
          </a>   
       </div>
       <span class="parcelas" *ngIf="produto.mostrar_parcelas">  
          Pague em até <strong>{{produto.parcelas}}x</strong> sem juros
       </span>
     </div>  
     <span style="display:none;" itemprop="sku">{{produto.id}}</span> 
 </div>
   