import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ModuloService } from 'src/app/services/modulo.service'; 

@Component({
  selector: 'modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss']
})
export class Modulo implements OnInit,AfterViewInit {

  @Input("posicao") position; 
  @Input("template") template          = null;
  @Input("classCustom") classCustom    = "";  
  @ViewChild("content") content:ElementRef; 
  @Input("servico") servico            = null;  
  @Input("carrinho") carrinho:any      = {};
  @Input("onEmit") onEmit              = new EventEmitter();    
  @Output("endLoader") endLoader       = new EventEmitter(); 
  @Output("endPagamento") endPagamento = new EventEmitter();
  @Input("valorPagamento") valorPagamento  = 0;  
  @Input("pagafacilData") pagafacilData = null; 

  public modulos:any          = [];
  @Input("onInit") onInit:any = true;

  constructor(
    private modulo:ModuloService
  ){}
  
  /**
   * 
   * End loader
   * 
   */
  _endLoader(){ 

    this.endLoader.emit(null);

  }
  /**
   * 
   * End Pagamento
   * 
   */
  _endPagamento(data){ 

    this.endPagamento.emit(data); 

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){ 

    this.modulo.updateModulosEmitter.subscribe(data =>{

      this.modulos = this.modulo.filterModulosByPosition(data,this.position);

    });
    this.modulo.getModulosEmitter.emit(); 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   **/
  ngOnInit() {

    if(this.onInit){
      this.onData(); 
    }   

  }
  ngAfterViewInit(){
    
    if(this.onInit == false || this.onInit == "false"){
      this.onData(); 
    }

  }

}
