<div class="menu-partial {{classCustom}} template-{{template}}">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content" #content> 
        <ul>
            <li>
                <a routerLink="/" title="Página Inicial">
                    <span>Home</span> 
                </a>    
            </li>
            <li *ngFor="let c of categorias"> 
                <a routerLink="/servicos/{{c.apelido}}" [title]="c.titulo">
                    <span>{{c.titulo}}</span>
                </a>    
            </li>
            <li>
                <a routerLink="/quem-somos" title="Quem Somos">
                    <span>Quem Somos</span> 
                </a>    
            </li>
            <li>
                <a routerLink="/contato" title="Contato">
                    <span>Contato</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   

     
