import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class ValidatorService {

  private messages = [];

  constructor() {
    
  }
  
  /**
   * 
   * Is Maior Idade
   * 
   * @param value 
   * @param index 
   * @param message 
   *
   **/
   validSize(value,index,message=null,size=0){

    value          = value == null ? "" : value;
    let sizeString = value.length;
    let status     = true;


    if(sizeString != size){  

      if(message != null){
        if(!this.inArray(index)){
          
          this.messages.push({
            index: index,
            message: message
          });
        }
      }  
      status = false;

    }
    return status;

  }
  /**
   * 
   * Is Maior Idade
   * 
   * @param value 
   * @param index 
   * @param message 
   *
   **/
   validQuantityTrajeto(value,valueQtd,index,message=null){

    let status = true;
  
    if(value < valueQtd){   

      if(message != null){
        if(!this.inArray(index)){
          
          this.messages.push({
            index: index,
            message: message
          });
        }
      }  
      status = false;

    }
    return status;

  } 
  /**
   * 
   * Is Maior Idade
   * 
   * @param value 
   * @param index 
   * @param message 
   *
   **/
   isEqual(value,value2,index,message=null){

    let status = true;
  
    if(value !== value2){  

      if(message != null){
        if(!this.inArray(index)){
          
          this.messages.push({
            index: index,
            message: message
          });
        }
      }  
      status = false;

    }
    return status;

  } 
  /**
   * 
   * Is Maior Idade
   * 
   * @param value 
   * @param index 
   * @param message 
   *
   **/
  isMaiorIdade(value,index,message=null){

    value      = value == null ? "" : value;
    let status = true;

    let years = moment().diff(moment(value,"DD-MM-YYYY"),'years');

    if(years < 18){  

      if(message != null){
        if(!this.inArray(index)){
          
          this.messages.push({
            index: index,
            message: message
          });
        }
      }  
      status = false;

    }
    return status;

  } 
  inArray(index){

    let status = false;

    this.messages.forEach(element => {
      
      if(element.index == index){
        status = true;
      }  

    });

    return status;

  }
  isEmpty(value,index,message=null){

    value      = value == null ? "" : value;
    let status = true;

    if(value.toString().length == 0){

      if(message != null){
        if(!this.inArray(index)){
          
          this.messages.push({
            index: index,
            message: message
          });
        }
      }  
      status = false;

    }
    return status;

  } 
  isTime(value,index,message=null){

    let status = true;

    if(value.toString().length >  0){

      let data    = value.split(":");
      let horas   = data[0];
      let minutos = data[1];

      if((horas < 0 || horas > 23) || (minutos < 0 || minutos > 59)){

        if(message != null){
          if(!this.inArray(index)){
            
            this.messages.push({
              index: index,
              message: message
            });
          }
        }  
        status = false;

      }

    }
    return status;

  } 
  isEmptyArray(value,index,message){
    
    if(value.length == 0){
    
      if(!this.inArray(index)){
        this.messages.push({
          index: index,
          message: message
        });
      }
    }

  }
  isEmptyListaPaxs(value,index,message){
    
    let status = false;

    for (var i = 0; i < value.length; i++) {
      
      if(value[i].status == true){
        status = true;
        break;
      }
      
    }

    if(status == false){
      
        if(!this.inArray(index)){
          this.messages.push({
            index: index,
            message: message
          });
        }
      }

  }
  validPaxTitular(tipo,index,message){
    
    if(tipo != "ADT" && tipo != "SNR"){
    
      if(!this.inArray(index)){
        this.messages.push({
          index: index,
          message: message
        });
      }
    }

  }
  isInteger(value,index,message){

    if(isNaN(parseInt(value))){
      if(!this.inArray(index)){
        this.messages.push({
          index: index,
          message: message
        });
      }
      return false;
    }
    return true;

  }
  /**
   * 
   * @param value 
   * @param index 
   * @param message 
   * 
   * 
   */
  isDateMaior(data1,data2,index,message){

    try{
      let dt1 = new Date(data1);
      let dt2 = new Date(data2);
      
      if(dt1 < dt2){ 

        if(!this.inArray(index)){
          this.messages.push({
            index: index,
            message: message 
          });
        }
        return false;  
      
      }
      
      return true;

    }catch(e){
      //alert(e.message)
    }  

  }
  /**
   * 
   * Validate Email
   * 
   * 
   */
  isEmail(value,index,message){

    if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))){
      
      if(!this.inArray(index)){
        this.messages.push({
          index: index,
          message: message 
        });
      }
      return false;
      
    }
    return true;

  }
  validCpf(value,index,message){
    
    if(!this._validCpf(value)){
      
      if(!this.inArray(index)){
        
        this.messages.push({
          index: index,
          message: message
        });
      }
    }
    
  }
  _validCpf(cpf){

    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;

    cpf = cpf.replace(new RegExp(/[.-]/g),""); 
    
    if (cpf.length < 11){
      return false;
    }  
    for(i = 0; i < cpf.length - 1; i++){
      if (cpf.charAt(i) != cpf.charAt(i + 1)){
        digitos_iguais = 0;
        break;
      }
    }  
    if(!digitos_iguais){
      
      numeros = cpf.substring(0,9);
      digitos = cpf.substring(9);
      soma = 0;
      
      for (i = 10; i > 1; i--){
        soma += numeros.charAt(10 - i) * i;
      }      
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)){
        return false;
      }  
      numeros = cpf.substring(0,10);
      soma = 0;
      for (i = 11; i > 1; i--){
        soma += numeros.charAt(11 - i) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)){
        return false;
      }      
      return true;

    }else{

      return  false;
    
    }
  }
  /**
   * 
   * @param value 
   * @param index 
   * @param message 
   * 
   * 
   */
  isDateMoment(data,index,message,formato='DD-MM-YYYY'){

    try{
      
      let status = moment(data,formato,true).isValid()
      
      if(!status){ 

        if(!this.inArray(index)){
          this.messages.push({
            index: index,
            message: message 
          });
        }
        return false;  
      
      }
      
      return true;

    }catch(e){
      //alert(e.message)
    }  

  }
  /**
   * 
   * Valida a data
   * 
   * @param value 
   * @param index 
   * @param message
   *  
   */
  isDate(value,index,message=null){

    let status = true;

    if(value.toString().length >  0){

      if(!this.validDate(value)){  

        if(message != null){
          if(!this.inArray(index)){
            
            this.messages.push({
              index: index,
              message: message
            });
          }
        }  
        status = false;

      }

    }
    return status;

  } 
  /**
   * 
   * Clear
   * 
   */
  clear(){
    this.messages = [];
  }
  /**
   * 
   * Retorna o array de messages
   *
   * 
   *  @return array
   * 
   */
  getMessages(){
    return this.messages;
  }
  /**
   * 
   * Retorna o array de messages em formato html
   *
   * 
   *  @return html
   * 
   */
  getMessagesHtml(){

    let html = "";

    this.messages.forEach(element => {

      html += "<p>";  
      html += element.message;
      html += "</p>";

    });


    return html;

  }
  /**
   * 
   * Retorna o array de messages em formato html
   *
   * 
   *  @return html
   * 
   */
  getMessagesText(){

    let html = "";

    this.messages.forEach(element => {

      html += element.message+"\x0A";

    });


    return html;

  }
  /**
   * 
   * Verifica se o array messages contém mensagens (Erros)
   *
   * 
   *  @return boolean
   * 
   */
  passes(){
    return this.messages.length == 0 ? true : false;
  }
  /**
   * 
   * Valida a data
   * 
   * @param s 
   * 
   */
  validDate(s){
    
    if ( ! /^\d\d\/\d\d\/\d\d\d\d$/.test(s) ) {
      return false;
    }
    const parts = s.split('/').map((p) => parseInt(p, 10));
    parts[1] -= 1;  
    const d = new Date(parts[2], parts[1], parts[0]);
    
    return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2];

  }


}
