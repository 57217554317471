<div class="produto-slider-item" *ngIf="template == 'one'">
      <div class="produtos slider-itens" #slider>   
            <div class="slider-item item-servicos" *ngFor="let item of data"> 
              <produto-item [data]="item"></produto-item>    
            </div>    
      </div> 
      <div class="swiper-button-prev sw-btn" (click)="prev()">
            <img src="/images/icons/slider/prev.svg" alt="Arrow" />
      </div>
      <div class="swiper-button-next sw-btn" (click)="next()">
            <img src="/images/icons/slider/next.svg" alt="Arrow" />
      </div>
</div>
<div class="produto-slider-item" *ngIf="template == 'two'">    
      <div class="produtos slider-itens" #slider>   
            <div class="slider-item item-servicos" *ngFor="let item of data"> 
              <produto-item-two [data]="item"></produto-item-two>     
            </div>     
      </div> 
      <div class="swiper-button-prev sw-btn" (click)="prev()">
            <img src="/images/icons/slider/prev.svg" alt="Arrow" />  
      </div>
      <div class="swiper-button-next sw-btn" (click)="next()">
            <img src="/images/icons/slider/next.svg" alt="Arrow" />
      </div>
</div>
