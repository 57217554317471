import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:any;
  @Input("classCustomTwo") classCustomTwo:any = "";
  public classCustom                          = "";
  public dataItem:any = {
    term: ""
  };

  constructor(
    private app: AppService,
    private router: Router
  ){} 
  
  /**
   * 
   * To Cart
   * 
   */
  toCart(){

    this.router.navigateByUrl("/carrinho"); 

  }
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
   setFixed(){

      let header = this.header.nativeElement;

      $(window).scroll(() => {
        
        var fromTop     = $(window).scrollTop();
        var windowWidth = $(window).width();
        
        if(fromTop > 100){
          header.classList.add("animated","fadeIn","fixed");
        }else{
          header.classList.remove("fixed","animated","fadeIn");
        }

      });

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    if(window.innerWidth >= 767){
      this.classCustom = "";
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.app.emitHeaderClass.subscribe(data => {

      this.classCustomTwo = data;

    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onData();
  }
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {
    this.setFixed();  
    this.onResize();
  }

}
