import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { HomePage } from 'src/app/pages/home-page/home-page.component';

const routes: Routes = [{ 
  path: '',     
  component: HomePage
}];


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule{ }
