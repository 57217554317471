<div class="menu-floating">
      <ul>
          <li>
              <div class="icon" (click)="_route('/carrinho')">
                <i class="fa-solid fa-cart-shopping"></i>
              </div>    
          </li>
          <li>  
              <div class="icon" (click)="_route('/minha-conta/favoritos')">
                <i class="fa-solid fa-heart"></i>
              </div>    
          </li>
          <li>
              <div class="icon" (click)="_route('/minha-conta/perfil')">
                <i class="fa-solid fa-user"></i>
              </div>      
          </li>
      </ul>    
</div>