import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DefaultValueAccessor, FormControl } from '@angular/forms';
import * as forms from '@angular/forms';

declare var $:any;

const VALUE_ACCESSOR = {
  provide: forms.NG_VALUE_ACCESSOR, 
  useExisting: forwardRef(() => DmInputComponent),
  multi: true,
};

@Component({
  selector: 'dm-input',
  templateUrl: './dm-input.component.html',
  styleUrls: ['./dm-input.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmInputComponent extends DefaultValueAccessor implements OnInit,AfterViewInit {
  
  @Input("setMask") setMask         = null;
  @Input("ngModel") value           = null;
  @Input("type") type               = "text";
  @Input("label") label             = null;
  @Input("rows") rows               = 5;
  @Input("name") name               = "name";
  @Input("max") max                 = null;
  @Input("placeholder") placeholder = "";
  @Input("disabled") disabled       = false;
  @ViewChild("datepicker") datepicker:ElementRef;
  @Input("info") info        = null;
  public setValueMoney = new EventEmitter();
  public _datepicker         = new FormControl();  
  public _date = null;
  public typeInput = "password";
  
  constructor(
    private renderer2: Renderer2, 
    private el: ElementRef 
  ) { 
      
    super(renderer2,el,false);
    
  }
  /**
   * 
   * Set type
   * 
   */
  setType(){

    this.typeInput = this.typeInput == "password" ? "text" : "password";

  }
  /**
   * 
   * Change value
   * 
   */
  onChangeValue(){
     
    super.writeValue(this.value);
    this.onChange(this.value);  
  
  }
  /**
   * 
   * Mask Change
   * 
   * @param value 
   * 
   */
  maskChange(value){
    super.writeValue(value); 
    this.onChange(value);   
  }
  /**
   * 
   * 
   * 
   */
  configDatePicker(){

    let self = this;

    $(this.datepicker.nativeElement).datepicker({
      dateFormat: "dd-mm-yy",
      buttonImageOnly: true,
      showOn: "button",
      monthNamesShort: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
      monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      buttonImage: "/assets/images/icons/calendar.svg",
      onSelect: function(value){
        
        self.value = value;
        self.onChangeValue();

      }
    });

  }
  setValues(){

    if(this.type == "money"){
      this.setValueMoney.emit(this.value);
    }

  }
  onDate(event: any): void {
    
    this.onChangeValue();    

  }
  /**
   * 
   * Formata o valor
   * 
   */
  formatDatepicker(){  

    let data = this.value.split("-");
    let mes  = parseInt(data[1]);

    if(!isNaN(mes)){

      mes = mes-1;
      this._date = new Date(data[2],mes,data[0]);
    
    }  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    this.setValues();
          
  }
  /**
   * 
   * Inicializa após carregar a VIEW
   * 
   * 
   */
  ngAfterViewInit(): void {
    
    if(this.type == "date"){
      setTimeout(() => {
        this.configDatePicker();
      },1000);
    }
    
  }

}
