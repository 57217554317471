import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataService } from './../services/data.service';
import { AppService } from './../services/app.service';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { ModuloService } from '../services/modulo.service';
import { CartService } from '../services/cart.service';

@Injectable({ 
    providedIn: 'root'
  })
export class ModulosResolver implements Resolve<any> {
    
    private title;
    public data                = null;
    public categorias_menu:any = [];

    constructor(
        private DataService:DataService, 
        private modulo: ModuloService,
        private app: AppService,
        private api: ApiService,
        private cart: CartService
    ){
        this.onData();
    }
    /**
     * 
     * Ouvintes
     * 
     */
    onData(){ 
        
        this.modulo.getModulosEmitter.subscribe(d =>{
            this.modulo.updateModulosEmitter.emit(this.data);
        });
        this.app.emitGetCategorias.subscribe(d =>{ 
            this.app.emitSetCategorias.emit(this.categorias_menu);
        });

    }  
    /**
     * 
     * Resolve
     * 
     * @param route 
     * @param state 
     * 
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ): Observable<any>|Promise<any>|any {
           
            let modulos      = this.DataService.getModulos();
            let configuracao = this.DataService.getConfiguracao();
            
            if(this.data == null){
            //if(modulos == null){

                return this.api.modulos().getModulos().subscribe(response =>{
                    
                    if(response.status == 1){
                        this.data = response.data;
                        this.modulo.updateModulosEmitter.emit(this.data);
                        this.DataService.setModulos(response.data);
                        this.DataService.setTotalCart(response.totalCart);
                        this.cart.emitirUpdateTotalCart.emit({
                            total: response.totalCart.total,
                            qtd: response.totalCart.qtd
                        });
                        this.DataService.setConfiguracao(response.configuracao);
                        this.categorias_menu = response.categoria_menu;
                        this.app.emitSetCategorias.emit(this.categorias_menu);
                        this.DataService.setCategoriasMenu(response.categoria_menu);
                        this.app.setMetaDadosInit(response.configuracao);
                        this.app.setStatusServidorEmitter.emit(1);
                    }
                    
                    this.app.emitChangePage.emit(route.params);

                    return response;
                   
                }, (err) => {
                    this.app.setStatusServidorEmitter.emit(500);
                });

           }else{
               this.data = modulos;  
               this.modulo.updateModulosEmitter.emit(this.data);
               this.app.setMetaDadosInit(configuracao);
               this.app.setStatusServidorEmitter.emit(1);
               this.app.emitChangePage.emit(route.params); 
               
               return this.data
           }
    }

 }
