import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'btn-favorito',
  templateUrl: './btn-favorito.component.html',
  styleUrls: ['./btn-favorito.component.scss']
})
export class BtnFavoritoComponent implements OnInit {

  @Input("id") id                 = null;
  @Input("isFavorito") isFavorito = false; 
  @Input("template") template     = "default";   
  public loader                   = false;  

  constructor(
    public api: ApiService,  
    public app: AppService
  ){}
  /** 
   * 
   * Send
   * 
   */
  favoritar(){

    try{

      this.loader = true;

      this.api.produto().favoritar(this.id).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.isFavorito = response.data;

          /*if(response.data === true || response.data === "true"){
            this.app.info("Este serviço foi salvo nos teus favoritos."); 
          }else{
            this.app.info("Este serviço foi retirado da sua lista de favoritos."); 
          }*/
          
        }else{

          let error   = this.api.formatError(response);
          this.app.info(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Init
   * 
   */ 
  ngOnInit():void{
  
  }

}
