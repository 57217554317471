import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DestinoComponent } from 'src/app/modulos/destino/destino.component';
import { SlideCarouselModule } from '../../galeria/slide-carousel/slide-carousel.module';


@NgModule({
  declarations: [
    DestinoComponent
  ],
  exports: [
    DestinoComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    SlideCarouselModule  
  ]
})
export class ModuloDestinoModule{}  
