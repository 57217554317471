<div [ngSwitch]="type" class="form-custom dm-input-content">
      <div *ngSwitchCase="'mask'">   
          <label [hidden]="!label">{{label}}</label>
          <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" [mask]="setMask" />
      </div>
      <div *ngSwitchCase="'integer'">
        <label [hidden]="!label">{{label}}</label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" integer />
      </div>
      <div *ngSwitchCase="'uppercase'">
        <label [hidden]="!label">{{label}}</label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" uppercase />
      </div>
      <div *ngSwitchCase="'apelido'">
        <label [hidden]="!label">{{label}} </label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" apelido />
      </div>
      <div *ngSwitchCase="'text'">
        <label [hidden]="!label">{{label}}</label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" />
      </div>
      <div *ngSwitchCase="'password'" class="input-password">
        <label [hidden]="!label">{{label}} </label>
        <input [type]="typeInput"  [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" />
        <div class="icon" (click)="setType()">
          <i class="fa-solid fa-eye" *ngIf="typeInput == 'text'"></i>
          <i class="fa-sharp fa-solid fa-eye-slash" *ngIf="typeInput == 'password'"></i>
      </div>   
      </div>
      <div *ngSwitchCase="'textarea'">
        <label [hidden]="!label">{{label}}</label>
        <textarea [(ngModel)]="value" (input)="onChangeValue()" [placeholder]="placeholder" [rows]="rows" [name]="name" [disabled]="disabled" [maxlength]="max"></textarea>
      </div>  
      <div *ngSwitchCase="'cpf'"> 
        <label>{{label}}</label>
        <input type="text" [(ngModel)]="value" placeholder="000.000.000-00" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="999.999.999-99" [disabled]="disabled" />
      </div> 
      <div *ngSwitchCase="'checkbox'" class="content-slide-toggle"> 
        <mat-slide-toggle [(ngModel)]="value" (change)="onChangeValue()" [name]="name" [disabled]="disabled">
          {{label}}
        </mat-slide-toggle>
      </div>
      <div *ngSwitchCase="'date'" class="date-content">  
        <label>{{label}} </label> 
        <input type="text" [(ngModel)]="value" placeholder="dd-MM-yyyy" (change)="onChangeValue()" (maskChange)="maskChange($event)" mask="99-99-9999" [name]="name"  #datepicker />
      </div>
      <div *ngSwitchCase="'money'"> 
        <label>{{label}} </label>
        <input type="text" [value]="value" (maskChange)="maskChange($event)" [name]="name" (setValueIn)="setValueMoney"  mask-money  />
      </div> 
      <div *ngSwitchCase="'number'"> 
        <label>{{label}}</label>
        <input type="text" matInput [value]="value" (maskChange)="maskChange($event)" [name]="name" (setValueIn)="setValueMoney"  mask-number  />
      </div> 
      <div *ngSwitchCase="'cnpj'"> 
        <label>{{label}}</label>
        <input type="text"[(ngModel)]="value" placeholder="00.000.000/0000-00" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="99.999.999/9999-99" [disabled]="disabled" />
      </div>
      <div *ngSwitchCase="'slide-toggle'" class="content-slide-toggle"> 
        <mat-slide-toggle [(ngModel)]="value" (change)="onChangeValue()" [name]="name" [disabled]="disabled">
          {{label}}
        </mat-slide-toggle>
      </div>  
      <div *ngSwitchCase="'time'">
        <label [hidden]="!label">{{label}}</label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" placeholder="HH:MM" mask="99:99" />
      </div>
      <div *ngSwitchCase="'celular'">
        <label [hidden]="!label">{{label}}</label> 
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" mask="(99) 99999-9999" />
      </div>
</div>
    
    
    