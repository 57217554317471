import { Injectable, ɵConsole } from '@angular/core';
import { ValidatorService } from './validator.service';

@Injectable()
export class ServicoService {

  constructor(
    private validator: ValidatorService
  ) { }

  /**
   * 
   * Agrupa por tipo de serviço
   * 
   */
  agrupaPorTipoServico(data){

    let response         = [];
    let countTipo        = 0;
    let y:any            = false;
    let contemRegular    = false; 
    let contemPrivativo = false;

    for(let index = 0;index < data.length;index++){
      
      y = this.inArray(data[index]["idTipoServico"],"idTipoServico",response);

      if(y == null){

        if(parseInt(data[index]["idTipoServico"]) == 1){
          contemRegular = true;
        }
        if(parseInt(data[index]["idTipoServico"]) == 2){
          contemPrivativo = true; 
        }
        response.push({
          tipoServico: data[index]["tipoServico"],
          idTipoServico: data[index]["idTipoServico"],
          lista:[data[index]]
        });
        countTipo++;

      }else{

        response[y].lista.push(data[index]); 

      }
      
    }

    return {
      response: response,
      countTipo: countTipo,
      contemRegular: contemRegular,
      contemPrivativo: contemPrivativo
    }

  }
  /**
   * 
   * Verifica se tem no array
   * 
   */
  inArray(value,campo,data){

    let index:any = null;

    for(let i = 0;i < data.length;i++) {
      
      if(data[i][campo] == value){
        index = i;
      }
      
    }

    return index;

  }
  /**
   * 
   * Valid Step 1
   * 
   */
  validStep1(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.macro_regiao_id == null){
      
      response = {
        data: "- Selecione a REGIÃO.",
        status: false
      }

    }
    return response;

  }
  /**
   * 
   * Valid Step 2
   * 
   */
  validStep2(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.tipo_servico_id == null){
      response = {
        data: "- Escolha o TIPO DE SERVIÇO.",
        status: false
      }
    }else{
      if(dataItem.tipo_servico_id == 2){
        if(dataItem.tipo_transporte_id == null){
          response = {
            data: "- Escolha o TRANSPORTE.",
            status: false
          }
        }
      }
    }
    return response;

  }
  /**
   * 
   * Valid Step 3
   * 
   */
  validStep3(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.hotel_id == null){
      
      response = {
        data: "- Selecione uma HOSPEDAGEM.",
        status: false
      }

    }else if(dataItem.solicitar_endereco){

      if(dataItem.endereco === null || dataItem.endereco === ""){
        response = {
          data: "- Informe o endereço.",
          status: false
        }
      }  

    }
    return response;
  
  }
  /**
   * 
   * Valid Step 4
   * 
   */
  validStep4(dataItem){

    let response:any = {
      data: "",
      status: true
    }
    this.validator.clear();
    this.validator.isEmpty(dataItem.data_utilizacao,"data_utilizacao","- Selecione a DATA.");

    if(dataItem.id_turno != 0){

      this.validator.isEmpty(dataItem.id_turno,"id_turno","- Informe o Horário.");

    }

    if(!this.validator.passes()){
      
      response = {
        data: this.validator.getMessagesHtml(),  
        status: false
      }

    }
    if(response.status == true){
      if(dataItem.valor_adt == 0 && dataItem.tipo_servico_id == 1){
        
        response.data   += response.status == true ? "- Esse serviço está sem valor" : "<br>- Esse serviço está sem valor";
        response.status = false;

      }else if(dataItem.valor_servico == 0 && dataItem.tipo_servico_id == 2){

        response.data  += response.status == true ? "- Esse serviço está sem valor" : "<br>- Esse serviço está sem valor";
        response.status = false;

      }
    }  
    return response;

  }
  /**
   * 
   * Valid qtd pax
   * 
   */
   validQtdPax(dataItem){

    let response:any = {
      data: "",
      status: true
    }
    let total = (dataItem.qtd_adt+dataItem.qtd_snr);
    if(total == 0){
      
      response = {
        data: "- Informe a quantidade de passageiros.",
        status: false
      }

    } 
    return response;

  }
  /**
   * 
   * Valid Step 1
   * 
   */
  validHorario(dataItem){

    let response = {
      data: "",
      status: true
    }

    this.validator.clear();
    this.validator.isEmpty(dataItem.horario_in,"horario_in","- Selecione o Horário de Partida.");
    this.validator.isEmpty(dataItem.horario_out,"horario_out","- Selecione o Horário de Retorno.");
    this.validator.isEmpty(dataItem.data_partida,"data_partida","- Informe a Data de Retorno."); 

    let dt1 = this.formatDate(dataItem.data_partida);
    let dt2 = this.formatDate(dataItem.data_utilizacao);  

    this.validator.isDateMaior(dt1,dt2,"data_partida","- A data partida não pode ser menor que "+dataItem.data_utilizacao+" ."); 


    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /**
   * 
   * Valid Step 1
   * 
   */
   validTrajeto(dataItem){

    let response = {
      data: "",
      status: true
    }

    this.validator.clear();
    this.validator.isEmpty(dataItem.id_local_pick_up,"id_local_pick_up","- Informe o Local de Saída.");
    this.validator.isEmpty(dataItem.id_local_drop_off,"id_local_drop_off","- Informe o Local de Destino.");


    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /**
   * 
   * Valid Dados Voo
   * 
   */
  validDadosVoo(dataItem){

    let response = {
      data: "",
      status: true
    }

    let pacote_in_out = dataItem.pacote_in_out;

    if(pacote_in_out === false || pacote_in_out === "false"){
      
      this.validator.clear();
      this.validator.isEmpty(dataItem.aeroporto,"aeroporto","- Informe o AEROPORTO.");
      this.validator.isEmpty(dataItem.cia_aerea_codigo,"cia_aerea","- Informe o CIA AÉREA.");
      this.validator.isEmpty(dataItem.voo,"voo","- Informe o VÔO.");
      this.validator.isEmpty(dataItem.hora_voo,"hora_voo","- Informe a Hora do Voo.");
      this.validator.isTime(dataItem.hora_voo,"hora_voo","- A hora do voo informada é inválida.");

    }else{

      this.validator.clear();
      this.validator.isEmpty(dataItem.aeroporto_chegada,"aeroporto_chegada","- Informe o AEROPORTO (Chegada).");
      this.validator.isEmpty(dataItem.cia_chegada_codigo,"cia_aerea","- Informe o CIA AÉREA (Chegada).");
      this.validator.isEmpty(dataItem.voo_chegada,"voo","- Informe o VÔO (Chegada).");
      //this.validator.isEmpty(dataItem.hora_chegada,"hora_chegada","- Informe a Hora de Chegada."); 
      /*if(dataItem.hora_chegada !== "" && dataItem.hora_chegada !== null){
        this.validator.isTime(dataItem.hora_chegada,"hora_chegada","- A Hora de Chegada possui um horário inválido.");  
      }*/
      this.validator.isEmpty(dataItem.aeroporto_partida,"aeroporto_partida","- Informe o AEROPORTO (Partida).");
      this.validator.isEmpty(dataItem.cia_partida_codigo,"cia_aerea_partida","- Informe o CIA AÉREA (Partida).");
      this.validator.isEmpty(dataItem.voo_partida,"voo_partida","- Informe o VÔO (Partida)."); 
      this.validator.isEmpty(dataItem.data_partida,"data_partida","- Informe a Data de Partida."); 

      let dt1 = this.formatDate(dataItem.data_partida);
      let dt2 = this.formatDate(dataItem.data_utilizacao); 

      this.validator.isDateMaior(dt1,dt2,"data_partida","- A data partida não pode ser menor que "+dataItem.data_utilizacao+" ."); 
      //this.validator.isEmpty(dataItem.hora_partida,"hora_partida","- Informe a Hora de Partida."); 
      /*if(dataItem.hora_partida !== "" && dataItem.hora_partidas !== null){
        this.validator.isTime(dataItem.hora_partida,"hora_partida","- A Hora de Partida possui um horário inválido."); 
      }*/ 

    }  
    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /***
   * 
   * Format Date
   * 
   * 
   */
  formatDate(data){

    let d = data.split("/");

    return d[1]+"/"+d[0]+"/"+d[2]; 

  }
  /**
   * 
   * Valid Step 4
   * 
   */
  validDadosPassageiros(data){

    let response = {
      data: "",
      status: true
    }
    
    for(let index = 0; index < data.length; index++) {
            
      if(!data[index].isSelect){
        this.validator.clear();
        this.validator.isEmpty(data[index].nome,"nome","- Informe o Nome.");
        this.validator.isEmpty(data[index].sobrenome,"sobrenome","- Informe o Sobrenome.");
        this.validator.isEmpty(data[index].documento,"documento","- Informe o RG ou Certidão de Nascimento.");

        if(data[index].tipo == "adt" || data[index].tipo == "snr"){
          this.validator.isEmpty(data[index].telefone,"telefone","- Informe o Celular.");
        }
        if(this.validator.isEmpty(data[index].cpf,"_cpf")){
          this.validator.validCpf(data[index].cpf,"cpf","- Informe um CPF válido.")
        }

        if(!this.validator.passes()){
          response.data   += "<div class='modal-passageiro'><h6>Passageiro "+(index+1)+"</h6>"; 
          response.data   += this.validator.getMessagesHtml();
          response.data   += "</div>";
          response.status  = false;
        }
      }  
      

    }

    return response;

  }
  /**
   * 
   * Retona as tarifas com base na data
   * 
   */
  getTarifasByDate(data,disponibilidade){

    let response = {
      valor_adt: 0,
      valor_chd: 0,
      valor_snr: 0,
      valor_servico: 0,
      tipo_contratacao: 1 
    }

    for(let index = 0;index < disponibilidade.length;index++) {
      
      if(disponibilidade[index].dia == data){
        response.valor_adt        = disponibilidade[index].tarifaADT;
        response.valor_chd        = disponibilidade[index].tarifaCHD;
        response.valor_snr        = disponibilidade[index].tarifaSNR;
        response.valor_servico    = disponibilidade[index].tarifaServico;
        response.tipo_contratacao = disponibilidade[index].tipoContratacaoTarifa;
        break;
      }
      
    }

    return response;

  }
  /**
   * 
   * Formatar tarifa
   * 
   */
  formatarTarifa(tarifa){

    tarifa = tarifa.replace(new RegExp(/[.]/g),"");
    tarifa = tarifa.replace(new RegExp(/[,]/g),".");

    return parseFloat(tarifa); 

  }
  /**
   * 
   * Retorna as tarfifas de uma data
   * 
   */
  getTarifasByDate2(d,idZonaHotel=null,idCidadeMunicipio=null,disponibilidade,tipo=1){

    let item               = null;
    let valor_chd          = 0;
    let valor_adt          = 0;
    let valor_snr          = 0;
    let valor_servico      = 0;
    let tipo_contratacao   = 1;
    let codigo_moeda       = "";
    let _idCidadeMunicipio = null;
    let _idZonaHotel       = null;
    let id_turno           = 0;
    let turno              = "";
    disponibilidade        = typeof(disponibilidade[0]) == "undefined" ? [disponibilidade] : disponibilidade;

    if(idCidadeMunicipio != null){
      _idCidadeMunicipio = idCidadeMunicipio.length == 0 ? null : idCidadeMunicipio;
    }
    if(tipo == 2){
      idCidadeMunicipio = null;
    }
    if(idZonaHotel != null){
      _idZonaHotel = idZonaHotel;
    }

    disponibilidade.forEach(element => {

      if(idZonaHotel != null && idCidadeMunicipio != null){
        
        if(element.dia == d && parseInt(element.idZonaRegiaoHotel) == parseInt(idZonaHotel) && parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio)){
          item = element;
        }

      }else if(idZonaHotel != null){  
        
        if(element.dia == d && parseInt(element.idZonaRegiaoHotel) == parseInt(idZonaHotel)){
          item = element
        }  

      }else{
        
        let _item      = element;
        let _tarifaADT = null;
        let tarifaADT  = null;

        if((element.dia == d) && (parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio)) && (item == null || item == "null")){ 
                
          item = element;
                  
        }else{

          if(!isNaN(parseInt(element.idCidadeMunicipio))){
            
            if((element.dia == d) && (parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio))){
              _tarifaADT = this.formatarTarifa(element.tarifaADT); 
              tarifaADT = this.formatarTarifa(item.tarifaADT);       
              
              if(_tarifaADT < tarifaADT){ 
                item = element; 
              }

            }else{
              if(element.dia == d){
                _tarifaADT = this.formatarTarifa(element.tarifaADT); 
                tarifaADT  = item == null ? null : this.formatarTarifa(item.tarifaADT);       
                
                if(tarifaADT == null){
                  item = element; 
                }else{
                  if(_tarifaADT < tarifaADT){ 
                    item = element; 
                  }
                }  
  
              }
            }
          }else{
            if(element.dia == d){
              _tarifaADT = this.formatarTarifa(element.tarifaADT); 
              tarifaADT  = item == null ? null : this.formatarTarifa(item.tarifaADT);       
              
              if(tarifaADT == null){
                item = element; 
              }else{
                if(_tarifaADT < tarifaADT){ 
                  item = element; 
                }
              }  

            }
          }  

        } 

      }
      
      
    });
    if(item != null){

      valor_adt        = this.formatarTarifa(item.tarifaADT);
      valor_chd        = this.formatarTarifa(item.tarifaCHD);
      valor_snr        = this.formatarTarifa(item.tarifaSNR);
      valor_servico    = this.formatarTarifa(item.tarifaServico);
      tipo_contratacao = item.tipoContratacaoTarifa;
      codigo_moeda     = item.codigoMoeda;
      id_turno         = typeof(item.idTurno) != "undefined" ? parseInt(item.idTurno) : 0;
      turno            = typeof(item.turno) != "undefined" ? item.turno : "ÚNICO"; 

      return {
        valor_adt: valor_adt,
        valor_chd: valor_chd,
        valor_snr: valor_snr,
        valor_servico: valor_servico,
        codigo_moeda: codigo_moeda,
        tipo_contratacao: tipo_contratacao,
        id_turno: id_turno,
        turno: turno
      }

    }else{
      
      let _data:any = {
        valor_adt: null,
        valor_chd: null,
        valor_snr: null,
        valor_servico: null,
        codigo_moeda: null,
        tipo_contratacao: null,
        id_turno: null,
        turno: null
      }
      if(tipo == 1){

        _data = this.getTarifasByDate2(d,idZonaHotel,_idCidadeMunicipio,disponibilidade,2);

        valor_adt     = _data.valor_adt;
        valor_chd     = _data.valor_chd;
        valor_snr     = _data.valor_snr;
        valor_servico = _data.valor_servico;
        codigo_moeda  = _data.codigo_moeda;
        tipo_contratacao = _data.tipo_contratacao;
        id_turno         = _data.id_turno;
        turno            = _data.turno;

      }else if(tipo == 2){
      
        _data = this.getTarifasByDate2(d,null,_idCidadeMunicipio,disponibilidade,3);

        valor_adt         = _data.valor_adt;
        valor_chd         = _data.valor_chd;
        valor_snr         = _data.valor_snr;
        valor_servico     = _data.valor_servico;
        codigo_moeda      = _data.codigo_moeda;
        tipo_contratacao  = _data.tipo_contratacao;
        id_turno          = _data.id_turno;
        turno             = _data.turno;

      }

    }
    
    return {
      valor_adt: valor_adt,
      valor_chd: valor_chd,
      valor_snr: valor_snr,
      valor_servico: valor_servico,
      codigo_moeda: codigo_moeda,
      tipo_contratacao: tipo_contratacao,
      id_turno: id_turno,
      turno: turno
    }
    

  }
  /***
   * 
   * Get turnos
   * 
   **/
  getTurnos(data,date){

    let d = [];

    for (let index = 0; index < data.length; index++) {
      
      if(data[index].dia == date){
        d.push({
          value: data[index].idTurno,
          text: data[index].turno
        });
      }
      
    }

    return d;

  }


}
