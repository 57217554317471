import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogItemComponent } from 'src/app/components/blog/blog-item/blog-item.component';
import { PartialModule } from '../../partial/partial.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [
    BlogItemComponent
  ],
  exports: [
    BlogItemComponent  
  ],
  imports: [
    CommonModule,
    PartialModule,
    LazyLoadImageModule
  ]
})
export class BlogItemModule{}
