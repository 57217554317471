<div class="module module-blog-two">
      <div class="module-title">
        <div class="container">
            <div class="content-title">
                <div class="box">
                    <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
                </div>
                <div class="box">
                    <h3 class="wow slideInRight" data-wow-delay="0.8s">{{data.subtitulo}}</h3>
                </div>
            </div>
        </div>    
      </div> 
      <div class="module-content">
            <div class="container">
                <div class="content-blog">
                    <div class="blog-items slider-items slick-padding-mobile" #slider>  
                        <div class="slider-item" *ngFor="let d of data.posts">
                            <blog-item [data]="d"></blog-item> 
                        </div>      
                    </div>
                    <div class="blog-navigate">
                        <div class="item prev" (click)="prev()"> 
                            <i class="fa-solid fa-angle-left"></i>
                        </div>
                        <div class="item next" (click)="next()">
                            <i class="fa-solid fa-angle-right"></i>
                        </div>
                    </div>
                </div>  
                <div class="clearfix"></div>
                <div class="content-button">
                    <button type="button" class="btn-two btn-icon" (click)="toBlog()">
                        <div class="icon">
                            <i class="fa-solid fa-angle-right"></i>
                        </div>    
                        <span>Ver mais posts</span>
                    </button>    
                </div>     
            </div>
        </div>       
</div>