import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {

  @Input("data") data:any = null; 
  @Output("reload") reload = new EventEmitter(); 

  constructor(){} 

  /**
   * 
   * Reload
   * 
   */
  _reload(){ 
    this.reload.emit();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}


}
