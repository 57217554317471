<div class="module module-destination paralax" style='background-image: url("/images/destino/banner/{{data.params.banner}}");'>
      <div class="module-title">
          <h2 class="wow slideInDown" data-wow-delay="0.4s">{{data.titulo}}</h2>
      </div>     
      <div class="module-content">
            <div class="container"> 
                <article class="description wow slideInUp margin-p" data-wow-delay="0.5s" [innerHtml]="data.params.texto"></article>
                <div class="see-more wow slideInDown" data-wow-delay="0.8s">
                    <a routerLink="/destino" title="Ver mais Sobre o Destino" (click)="toRoute()">
                        <span>Ver Mais</span>
                    </a>   
                </div>   
            </div>    
      </div> 
      <div class="bg"></div>   
</div>

