import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-produto-destaque-two',
  templateUrl: './produto-destaque-two.component.html',
  styleUrls: ['./produto-destaque-two.component.scss']
})
export class ProdutoDestaqueTwoComponent implements OnInit, AfterViewInit{

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  @ViewChild("slider") slider:ElementRef;
  
  constructor(){}
    
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '30px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {}
  /**
   * 
   * After view init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
