import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnUsuarioLoginComponent } from 'src/app/components/btns/btn-usuario-login/btn-usuario-login.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@NgModule({
  declarations: [
    BtnUsuarioLoginComponent
  ],
  exports: [
    BtnUsuarioLoginComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule
  ],
  providers: [
  ]
})
export class BtnUsuarioLoginModule { }
