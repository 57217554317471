<header class="header {{classCustomTwo}}" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-6">
                    <modulo posicao="logo"></modulo>
                </div>
                <div class="col-md-8 content-actions col-6">
                    <div class="actions">
                        <ul>
                            <li>
                                <form class="form form-custom form-template-two">
                                    <div class="content-input">
                                        <input type="text" [(ngModel)]="dataItem.term" name="term" placeholder="O que você está buscando?" />
                                        <div class="icon">
                                            <img src="images/icons/lupa-01.svg" alt="Pesquisar pelo melhor passeio" width="20px" height="20px" />
                                        </div>
                                    </div>
                                </form>
                            </li>
                            <li>
                                <btn-cart></btn-cart> 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>      
    </div>
    <div class="content-menu">
        <div class="container">
            <menu-partial [classCustom]="classCustom"></menu-partial>
        </div>
    </div>
</header>  

