<div class="page-error">
    <div class="container">
        <div class="alert alert-danger">
            <div class="page-error-title"> 
                <h3>Aconteceu algo inesperado ao carregar a página.</h3>
            </div>    
            <div class="page-error-content">
                <p class="text-center">Se o erro permanecer entre em contato com a nossa equipe.</p>
                <button type="button" class="btn-one" (click)="_reload()">
                    Recarregar
                </button>    
            </div>
        </div>        
    </div>    
</div>
