<a (click)="toRoute()" href="/blog/{{data.apelido_categoria}}/{{data.apelido}}">
      <div class="blog-item-two">
            <div class="capa" *ngIf="data.imagem != null">
                  <img [src]="data.imagem" [alt]="data.titulo" width="100%" /> 
            </div> 
            <div class="capa" *ngIf="data.imagem == null"> 
                  <img src="/images/sem-foto/default.jpg" [alt]="data.titulo" width="100%" />
            </div> 
            <div class="content">
                  <h4>{{data.titulo}}</h4>
                  <div class="link" *ngIf="showLink">
                        <a (click)="toRoute()" href="/blog/{{data.apelido_categoria}}/{{data.apelido}}" title="Leia Mais">
                              <span>Leia Mais</span>
                        </a>    
                  </div>    
            </div>    
      </div> 
</a>    

   