import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogComponent } from 'src/app/modulos/blog/blog.component';
import { PartialModule } from '../../partial/partial.module';
import { BlogItemModule } from '../../blog/blog-item/blog-item.module';

@NgModule({
  declarations: [
    BlogComponent
  ],
  exports: [  
    BlogComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    BlogItemModule  
  ]
})
export class ModuloBlogModule{}
