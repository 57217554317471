<div class="module module-horarios">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <h3 class="title-h3">{{data.titulo}}</h3>
      </div> 
      <div class="module-content">
            <div class="horarios">
                  <div class="horarios-title">
                        <strong>{{data.params.horario_titulo}}</strong>
                  </div>
                  <div class="horarios-content" [innerHtml]="data.params.horario_texto"></div>
            </div>
      </div>
</div>