<div [ngSwitch]="template">
  <section *ngSwitchCase="'home'" class="modulo modulo-contato-home default-home" itemscope itemtype="https://schema.org/Organization">
    <div class="container">   
      <h2 class="wow animTypeWriter" data-delay="0.5s">Nossos canais de atendimento</h2>
      <div class="list-contato">
        <div class="item wow fadeIn" data-wow-delay="0.5s" (click)="openWhatsApp()">
          <img lazyLoad="/images/template/icons/whatsapp-2.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="WhatsApp" />
          <div class="text">
            <strong>WhatsApp</strong><br>
            <span class="value">{{data.params.whatsapp}}</span>
          </div>  
        </div>
        <div class="item wow fadeIn" data-wow-delay="0.7s">
          <img lazyLoad="/images/template/icons/telefone.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Telefone" />
          <div class="text">
            <strong>Telefone</strong><br>
            <a href="tel:{{data.params.celular}}" class="value" title="Telefone">{{data.params.celular}}</a>  
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="0.8s">
          <img lazyLoad="/images/template/icons/email.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Email" />
          <div class="text">
            <strong>Email</strong><br>
            <a href="mailto:{{data.params.email}}" class="value" title="Email">{{data.params.email}}</a>
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="1s"> 
          <img lazyLoad="/images/template/icons/instagram-3.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Instagram" />
          <div class="text">
            <strong>Instagram</strong><br>
            <a href="{{data.params.instagram_link}}" target="_blank" class="value" title="Instagram">{{data.params.instagram_conta}}</a>
          </div>  
        </div>   
      </div>
      <div class="bg-contato"></div>
    </div>
  </section>
  <section *ngSwitchCase="'footer'" class="default-footer">
    <h3 class="title-h3">Contato</h3>
    <div class="contato-list">
      <div class="item" *ngIf="data.params.email != '' && data.params.email != null">
        <strong>E-mail: </strong>
        <span>{{data.params.email}}</span>
      </div>
      <div class="item" *ngIf="data.params.celular != '' && data.params.celular != null">
        <strong>Telefone: </strong>
        <span>+55 {{data.params.celular}}</span>
      </div>
      <div class="item" *ngIf="data.params.whatsapp != '' && data.params.whatsapp != null">
        <strong>WhatsApp: </strong>
        <span>+55 {{data.params.whatsapp}}</span>
      </div>
    </div>
  </section>
  <section *ngSwitchCase="'page-contato-email'" class="page-contato-email">
    <div class="card-item card-item-contact wow slideInUp" data-wow-delay="0.4s">
      <div class="card-item-header">
          <div class="icon">
            <i class="fa-solid fa-envelope"></i>
          </div>
          <h3>{{data.params.email}}</h3>     
      </div>
    </div> 
  </section>
</div>  


