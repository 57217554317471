<div [ngSwitch]="template" itemscope itemtype="https://schema.org/Organization">
  <div class="modulo modulo-endereco" *ngSwitchCase="null">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
      <h3>{{data.titulo}}</h3>
    </div> 
    <div class="modulo-content"> 
      <div class="icon">
        <i class="material-icons">
          location_on
        </i>
      </div>  
      <article class="endereco" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">{{data.params.rua}}, nº {{data.params.numero}} | {{data.params.bairro}}</span><br>
        <span itemprop="addressLocality">{{data.params.cidade}}</span><br><span itemprop="postalCode">Cep: {{data.params.cep}}</span>
      </article>  
    </div>  
  </div>
  <div class="modulo modulo-endereco-footer col-endereco" *ngSwitchCase="'footer'">
    <h3 class="title-h3">{{data.titulo}}</h3>
    <article (click)="open()">
      <p>
        {{data.params.rua}}, {{data.params.numero}} {{data.params.bairro}}, {{data.params.cidade}} - CEP: {{data.params.cep}}
      </p>
    </article>
  </div>
</div>
