import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomePage } from 'src/app/pages/home-page/home-page.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { PartialModule } from '../partial/partial.module';
import { ModuloModule } from '../modulo/modulo.module';
import { BtnUsuarioLoginModule } from '../btn/btn-usuario-login/btn-usuario-login.module';

@NgModule({
  declarations: [
    HomePage
  ],
  exports: [
    HomePage
  ],
  imports: [
    BtnUsuarioLoginModule,  
    ModuloModule,
    LazyLoadImageModule,
    PartialModule,
    CommonModule,
    HomeRoutingModule
  ],
  providers: [
 
  ]
})
export class HomeModule { }
