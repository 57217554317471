<div class="modulo banner-slider-slick" (window:resize)="onResize()">
  <div #banner class="banner-slider-slick-content">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img *ngIf="width <= 700" width="100%" height="300px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
        <img *ngIf="width > 700" width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="300px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="300px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>   
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img *ngIf="width <= 700" width="100%" height="300px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>   
      </div>
    </div>
  </div>
  <div class="navigate" *ngIf="data.banners.length > 1">
    <div class="navigate-item prev" (click)="prev()">
        <div class="content">
            <img src="images/icons/prev.svg" alt="Prev" width="40px" />
        </div>
    </div> 
    <div class="navigate-item next" (click)="next()">
        <div class="content">
            <img src="images/icons/next.svg" alt="Next" width="40px" />
        </div>
    </div>    
</div>  
</div>


