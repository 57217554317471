import { Directive, ElementRef, OnInit } from '@angular/core';

declare var $:any;

@Directive({
  selector: '[servicoObservacoesFormat]'
})
export class ServicoObservacoesFormatDirective implements OnInit{
  
  public html = "";

  constructor(
    public el: ElementRef
  ){
    
    setTimeout(() => {
      //this.html = $(this.el.nativeElement).html(); 
      this.formatHtml();
    });
    
    
  }
  /**
   * 
   * Format Html
   * 
   */
  formatHtml(){

    let element = this.el.nativeElement;
    let html    = "";

    $(element).find("li").map(function(index) {

      html  = "";
      html += "<span>";
      html += "<i class='fal fa-exclamation'></i>";
      html += $(this).text();
      html += "</span>";

      $(this).html(html);
      //let replacement = $(element).find("li").text($(this).text()).get(0);

      //$(element).find("li").text($(this).text()).get(0);

      if(index === 0){
 
        //$(replacement).text($(replacement).text().toUpperCase());
      
      }

    }); 

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  ngOnInit(): void {
    
    

  }


}
