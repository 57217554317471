import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ModuloService {

  public updateModulosEmitter = new EventEmitter();
  public getModulosEmitter    = new EventEmitter();

  constructor(){}

  /**
   * 
   * Filtra o módulo
   * 
   * @param modulos 
   * @param position 
   * 
   */
  filterModulosByPosition(modulos,position){

    let out = [];

    if(modulos != null){
      
      modulos.forEach(element => {

        if(element.posicao == position){
          element.params = typeof(element.params) == "string" ? JSON.parse(element.params) : element.params;
          out.push(element);
        }


      });
    }  

    return out;

  }

 
}
